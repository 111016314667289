import React from "react";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";

function Portfolio(props) {
  const { id, featuredImage, title, filesource } = props.data;

  const getNospaceTitle = (filesource) => {
    let tempArr = filesource.split("/");
    let fileName = tempArr[tempArr.length - 1];
    let getName = fileName.slice(0, -3);
    return getName;
  };
  return (
    <div className="mi-blog">
      <div className="mi-blog-image">
        <Link
          to={`portfolio/portfolio-details/${id}/${getNospaceTitle(
            filesource
          )}`}
        >
          <ProgressiveImage
            src={featuredImage}
            placeholder="/images/blog-image-placeholder.png"
          >
            {(src) => <img src={src} alt={title} />}
          </ProgressiveImage>
        </Link>
      </div>
      <div className="mi-blog-content">
        <h5>
          <Link
            to={`portfolio/portfolio-details/${id}/${getNospaceTitle(
              filesource
            )}`}
          >
            {title}
          </Link>
        </h5>
      </div>
    </div>
  );
}

export default Portfolio;
