import Markdown from "markdown-to-jsx";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

function PortfolioDetails(props) {
  const [content, setContent] = useState("");
  const portifolioId = props.match.params.id;
  const portifolioFile = props.match.params.title;
  const fileName = `${portifolioFile}.md`;

  useEffect(() => {
    import(`../portfolio/${fileName}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setContent(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });

  return (
    <Layout>
      <Helmet>
        <title>José Lima - Senior Software Engineer</title>
        <meta
          name="description"
          content="I'm a Software Engineer (Front-End) with a great passion for
          develop apps that surprise and delight."
        />
      </Helmet>
      <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Markdown>{content}</Markdown>
        </div>
      </div>
    </Layout>
  );
}

export default PortfolioDetails;
