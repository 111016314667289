import Mock from "../mock";

const database = {
  information: {
    name: "José Lima",
    aboutContent: `I'm a Software Engineer with a great passion for developing apps that surprise and delight. With ${
      new Date().getFullYear() - 2016
    } years of experience in web application development, I had the opportunity to work with the best technologies on the market. I am a t-shaped professional, specializing in front-end development.`,
    age: `${new Date().getFullYear() - 1996}`,
    phone: "",
    nationality: "Brazilian",
    language: "English, Portuguese",
    email: "",
    address: "",
    freelanceStatus: "Available",
    socialLinks: {
      facebook: "",
      twitter: "",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/joselimadev/",
      dribbble: "",
      github: "https://github.com/joselimadev/",
      codepen: "",
      cvfile: "/files/Resume-Jose-Lima.pdf",
    },
    brandImage: "/images/brand-image.jpg",
    brandImageAlt: "jose lima software enginner",
    aboutImage: "/images/about-image.jpg",
    aboutImageLg: "/images/about-image-lg.jpg",
    cvfile: "/files/Resume-Jose-Lima.pdf",
    certifications: [
      {
        name: "Exam 480: Programming in HTML5 with JavaScript and CSS3",
        link: "https://www.credly.com/badges/de481aa9-ced1-4db6-b1af-0b8f1c1e80d7",
        badge: "/images/certs/microsoft-badge.png",
      },
      {
        name: "Scrum Foundation Professional Certificate - SFPC™",
        link: "https://www.credly.com/badges/151e1f6d-c6e9-4b93-8f96-205cce6f3ad0",
        badge: "/images/certs/spfc-badge.png",
      },
      {
        name: "Architecting on AWS",
        link: "/files/architecting-on-aws.pdf",
        badge: "/images/certs/aws-badge.svg",
      },
    ],
  },
  services: [
    {
      title: "FRONT-END DEVELOPMENT",
      icon: "code",
      details: "",
    },
    {
      title: "BACK-END DEVELOPMENT",
      icon: "nodejs",
      details: "",
    },
    {
      title: "MOBILE DEVELOPMENT",
      icon: "mobile",
      details: "",
    },
  ],
  skills: [
    {
      title: "HTML5",
      value: 100,
      duration: `${new Date().getFullYear() - 2016}`,
      icon: "/images/tech/html5.svg",
    },
    {
      title: "CSS3",
      value: 100,
      duration: `${new Date().getFullYear() - 2016}`,
      icon: "/images/tech/css3.svg",
    },
    {
      title: "Javascript",
      value: 100,
      duration: `${new Date().getFullYear() - 2016}`,
      icon: "/images/tech/js.svg",
    },
    {
      title: "SQL",
      value: 100,
      duration: `${new Date().getFullYear() - 2016}`,
      icon: "/images/tech/sql.svg",
    },
    {
      title: "Node",
      value: 84,
      duration: `${new Date().getFullYear() - 2017}`,
      icon: "/images/tech/node.svg",
    },
    {
      title: "TypeScript",
      value: 66,
      duration: `${new Date().getFullYear() - 2018}`,
      icon: "/images/tech/typescript.svg",
    },
    {
      title: "VueJS",
      value: 84,
      duration: `${new Date().getFullYear() - 2017}`,
      icon: "/images/tech/vuejs.svg",
    },
    {
      title: "Angular",
      value: 66,
      duration: `${new Date().getFullYear() - 2018}`,
      icon: "/images/tech/angular.svg",
    },
    {
      title: "ReactJS",
      value: 66,
      duration: `${new Date().getFullYear() - 2018}`,
      icon: "/images/tech/react.svg",
    },
  ],
  otherSkills: [
    {
      title: "NextJS",
      icon: "/images/tech/nextjs.svg",
    },
    {
      title: "TailwindCSS",
      icon: "/images/tech/tailwind.svg",
    },
    {
      title: "Storybook",
      icon: "/images/tech/storybook.svg",
    },
    {
      title: "Jest",
      icon: "/images/tech/jest.svg",
    },
    {
      title: "Flutter",
      icon: "/images/tech/flutter.svg",
    },
    {
      title: "MongoDB",
      icon: "/images/tech/mongodb.svg",
    },
    {
      title: "Amazon Web Services",
      icon: "/images/tech/aws.svg",
    },
    {
      title: "Docker",
      icon: "/images/tech/docker.svg",
    },
    {
      title: "Jenkins",
      icon: "/images/tech/jenkins.svg",
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2020 - Present",
        position: "Senior Software Engineer",
        company: "Pan Bank",
        details:
          "<b>[2021 - Present]</b><br/> Angular Front-End Developer. Development and maintenance of the internal financial module for the management of payroll loan proposals <br/><br/> <b>[2020 - 2021]</b><br/> Angular and React Front-End Developer. Development and maintenance of the financial module that guarantees the release of credit with the alienation of one or more vehicles, allowing for a credit limit linked to the vehicle inventory.",
      },
      {
        id: 2,
        year: "2018 - 2020",
        position: "Software Engineer",
        company: "Avanade",
        details:
          "<b>[BTG PACTUAL BANK PROJECT] (MAI/2020 - DEZ/2020)</b><br/> React Front-End Developer. Dashboard development (statement, transactions, consolidated balances, etc.) for PMEs accounts using React with TypeScript. Building the customer journey on a digital banking platform. <br/><br/> <b>[CLARO BRASIL PROJECT] (DEZ/2018 - MAI/ 2020)</b><br/> Angular Front-End Developer. Migration of old and unsupported WLP (Web Logic Portal from Oracle) technology to modern technologies (Angular 7, Node, MongoDB and AWS) with the goal of optimizing the process of continuous improvement. I also participated in the unification between NET and Claro brands.",
      },
      {
        id: 3,
        year: "2016 - 2018",
        position: "Software Engineer Intern",
        company: "ProRadis",
        details:
          "The maintenance, support and development of SmartRIS applications (system of management of radiological clinics integrating PACS, RIS and PEP), Image2Doc (customized application for images, reports and documents for requesting physicians and patients) and APP I2D (Custom application with the visual identity of the partner clinic).",
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "2016 - 2019",
        graduation: "System Analysis and Development, Bachelor of Technology",
        university: "Centro Universitário FMU | FIAM-FAAM",
      },
      {
        id: 2,
        year: "2014 - 2015",
        graduation: "Certificate Of Computer Technician",
        university: "CPS - Centro Paula Souza",
      },
    ],
  },
  portfolio: [
    {
      id: 8,
      title: "Go Pan - Pan Bank",
      featuredImage: "/images/gopan-bancopan.jpg",
      filesource: "../../portfolio/gopan-bancopan.md",
      createDay: "01",
      createMonth: "December",
      createYear: "2020",
    },
    {
      id: 7,
      title: "Auto Pan - Pan Bank",
      featuredImage: "/images/autopan-bancopan.jpg",
      filesource: "../../portfolio/autopan-bancopan.md",
      createDay: "01",
      createMonth: "December",
      createYear: "2020",
    },
    {
      id: 6,
      title: "Giro Pan - Pan Bank",
      featuredImage: "/images/giropan-bancopan.jpg",
      filesource: "../../portfolio/giropan-bancopan.md",
      createDay: "01",
      createMonth: "December",
      createYear: "2020",
    },
    {
      id: 5,
      title: "Business - BTG Pactual Bank",
      featuredImage: "/images/business-btgpactual.jpg",
      filesource: "../../portfolio/business-btgpactual.md",
      createDay: "01",
      createMonth: "December",
      createYear: "2019",
    },
    {
      id: 4,
      title: "E-COMMERCE - Claro Brazil",
      featuredImage: "/images/e-commerce-claro.jpg",
      filesource: "../../portfolio/e-commerce-claro.md",
      createDay: "01",
      createMonth: "December",
      createYear: "2018",
    },
    {
      id: 3,
      title: "Customized Clinic APP - ProRadis",
      featuredImage: "/images/app2doc-proradis.jpg",
      filesource: "../../portfolio/app2doc-proradis.md",
      createDay: "01",
      createMonth: "December",
      createYear: "2016",
    },
    {
      id: 2,
      title: "Image2Doc - ProRadis",
      featuredImage: "/images/image2doc-proradis.jpg",
      filesource: "../../portfolio/image2doc-proradis.md",
      createDay: "01",
      createMonth: "December",
      createYear: "2016",
    },
    {
      id: 1,
      title: "SmartRIS - ProRadis",
      featuredImage: "/images/smartris-proradis.jpg",
      filesource: "../../portfolio/smartris-proradis.md",
      createDay: "01",
      createMonth: "December",
      createYear: "2016",
    },
  ],
  contactInfo: {
    phoneNumbers: ["+5511977897729"],
    emailAddress: ["josecarlosdeoliveira51@gmail.com"],
    address: "",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/otherSkills").reply((config) => {
  const response = database.otherSkills;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/portfolio").reply((config) => {
  const response = database.portfolio;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
